$color-color: hsl(0, 0%, 100%);
$btn-primary:#ffc107;

/* $color-html: hsl(15, 100%, 60%);
$color-css: hsl(200, 100%, 60%);
$color-scss: hsl(300, 100%, 60%);
$color-js: hsl(60, 100%, 60%);
$color-ts: hsl(220, 100%, 60%);
$color-react: hsl(180, 100%, 60%);
$color-english: rgb(221, 51, 255);

$porcent-html: 66.67;
$porcent-css: 66.67;
$porcent-scss: 60;
$porcent-js: 50;
$porcent-ts: 15;
$porcent-react: 25;
$porcent-english: 25; */

$color-hr: rgb(250, 0, 0);


$bold:600;

$bodyBackground:#191923;
$containBackground: linear-gradient(159deg,#2d2d3a 0%,#2b2b35 100%);
$elementsBackground:#ffc107;
$formBackground: linear-gradient(159deg,#252532 0%,#23232d 100%);

$text: #8c8c8e;
$header: #fafafc;




@function strokeDashoffset($num) {
	@return 185 - (185 * $num) / 100;
}