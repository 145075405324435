@import '../components/variables.scss';

.header{

    &Block {
        max-height: 350px;
        position: relative;
        margin-top: 4rem;
    }

    &Picture {
        position: absolute;
        max-width: 400px;
        max-height: auto;
        bottom: 0;
        left: 50%;
        z-index: 5;
    }
}

.backgroundHeader {
    max-height: 350px;
    position: relative;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
}


.transparent {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    overflow: hidden;
    background: $formBackground;
    opacity: 0.8;
    z-index: 5;
}

 .Typewriter {
    color: white;
    margin: auto 0.3rem;

    &__cursor {
        color: $elementsBackground;
    }
}

.typing {
    position: absolute;
    display: flex;
    margin-top: 1rem;
    margin-left: 2rem;
    top: 53%;
    font-size: 1rem;
    z-index: 5;

    & i {
        color: $elementsBackground;
    }
}

.homepageWelcome {
    padding: 1rem 2rem;
    color: white;
    position: absolute;
    top: 10%;
    z-index: 5;
    font-weight: 700;
}

.welcomeBlock_postheader {
    padding: 1rem 2rem;
    margin-top: 2rem;
    background-image: $containBackground;
    color: $text;
    text-align: justify;

    & p {
        margin: 0;
    }
}

@media screen and (max-width: 1220px) {
    .headerPicture {
        left: 38%;
    }
}

@media screen and (max-width: 720px) {
    .headerPicture {
        display: none;
    }

    .welcomeBlock_postheader {
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 390px) {
    .welcomeBlock_postheader {
        padding: 0.5rem;
    }
}
