@import '../components/variables.scss';


.aboutmeWelcome {
    padding: 1rem 2rem;
    color: $text;
    background-image: $containBackground;
    text-align: justify;
}

.badEnglish_block {
    position: relative;
    color: $elementsBackground;
    cursor: pointer;
    width: fit-content;

    .badEnglish {
        display: none;
        z-index: 50;
    }

    &:hover>.badEnglish,
    &:active>.badEnglish {
        position: absolute;
        bottom: 0;
        left: 0;
        display: block;
        max-width: 300px;
        z-index: 10;
    }
}

@media screen and (max-width: 720px) {
    .aboutmeWelcome {
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 390px) {

    .badEnglish_block {
    
        &:hover>.badEnglish,
        &:active>.badEnglish {
            max-width: 200px;
        }
    }

    .aboutmeWelcome {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 240px) {

    .badEnglish_block {
    
        &:hover>.badEnglish,
        &:active>.badEnglish {
            max-width: 150px;
        }
    }
}






