@import 'variables.scss';
@import 'mixins.scss';

*:hover {
  transition-duration: 0.2s;
}

.sidebar {
    position: fixed;
    border: 1px solid $bodyBackground;
    width: 14.6875rem /* 235px -> 14.6875rem */;
    grid-column: 1/2;
    grid-row: 1/4;
    background-image: $containBackground!important;
    color: white;
    margin: 2rem 1rem 0rem 1rem;
  }

.social_menu a {
    margin-bottom: 0;
}


//PROFILE BLOCK SCSS

.profile-block {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 30px);
    overflow: auto;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
      }
  
          &__contacts{
            @include sidebar-information;
              & .icon {
                @include iconElement;
              }
  
              & a,
              & p {
                text-decoration: none;
                color: $text;
                margin-bottom: 1rem;
              }
  
              & a:hover,
              & a:active {
                color: $elementsBackground;
              }
          }
  
          &__picture {
            margin: 0 auto;
          
            & img {
            max-width: 150px;
            border-radius: 50%;
            margin-top: 1rem;
            border: 3px solid $elementsBackground;
          }

            &_content {
                text-align: center;
                padding-bottom: 2rem;
                border-bottom: 0.1px solid #cecece25;
                & p {
                    margin-top: 0.5rem;
                    margin-bottom: 0;
                    color: $header;
                    line-height: 1rem;
                }
        
                & span {
                    color: $text;
                    font-size: 0.9rem;
                }
        
            }
        }

        &__post-content {
            overflow: auto;
            scrollbar-width: none;
            padding: 2rem 1rem 50px 1rem;
            background-image: $formBackground;
            height: calc(100vh - 30px);

            &::-webkit-scrollbar {
                display: none;
              }
        }
  }
  
  .social_menu {
    display: flex;
    grid-column: 1/3;
    justify-content: space-between;
    text-decoration: none;
  
      & a {
        @include a_i_Element;
        text-decoration: none;
            
         &:hover {
          color: $header;
        } 
      }
  }
  
  //PROGRESS BAR NAVIGATION
  
  @keyframes prog_animation {
    0% {
        width: 0;
    }
    100% {
        width: 100;
      }
  }
  
  
.progress-bar {   
      animation-name: prog_animation!important;      
      animation-duration: 3s;
      background-color: $elementsBackground;
      color: $text;
      height: 0.4rem;
  }

.download_cv {
    position: absolute;
    width: 100%;
    background-image: $containBackground!important;
    margin-top: 3rem;
    bottom: 0;
    left: 0;
    text-align: center;
    padding: 0.344rem 0 0.344rem 0;
    margin-bottom: .125rem /* 2px -> .125rem */;
    border-top: 0.1px solid #cecece25;

        & a {
            color: $text;
            text-decoration: none;
        

            &:hover,
            &:active {
                color:$elementsBackground;
            }
        }

        & i {
            color: $elementsBackground;
        }

        & .fa-download {
            margin: 0 0.4rem;
        }
}
