@import '.././components/mixins';
@import '../components/variables.scss';

.projects.project-block {
    background-color: $bodyBackground;
}

.project-block {
    &__header {
        text-align: center;
        font-size: 1.75rem;
    }
    &__postheader {
        margin-top: 1rem;
    }
    &__content {
        color: $text;
        text-align: justify;
    }
    &__address {
        position: relative;
        display: inline-block;
    }

    &__image {
        max-width: 100%;
        transition: transform .3s;
        overflow: hidden;

        &:hover,
        &:active {
            transform: scale(1.02);
        }
    }

    &__first,
    &__second,
    &__third,
    &__four,
    &__five,
    &__six,
    &__seven,
    &__eight,
    &__nine {
    background-image: $containBackground;
    padding: 1rem 2rem;
}
}


.clickhere {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    &-link {
        opacity: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        background-color: white;
        text-decoration: none;
        font-size: 2rem;
        width: 100%;
        height: 100%;
        text-align: center;

            & span {
                font-size: 1.3rem;
            }

            &:hover,
            &:active {
                opacity: 0.9;
                color: $elementsBackground;
                transition: 0.5s ease;
                font-size: 3rem;
                font-weight: 600;
            }     
    }
}



@media screen and (max-width: 720px) {

    .main {
        margin: 0;;
    }

    .project-block__first,
    .project-block__second,
    .project-block__third,
    .project-block__four,
    .project-block__five,
    .project-block__six,
    .project-block__seven,
    .project-block__eight,
    .project-block__nine {
        background-image: $containBackground;
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 500px) {
    .clickhere-link:hover {
        font-size: 2rem;
    }
}

@media screen and (max-width: 390px) {
    .project-block__first,
    .project-block__second,
    .project-block__third,
    .project-block__four,
    .project-block__five,
    .project-block__six,
    .project-block__seven,
    .project-block__eight,
    .project-block__nine {
        padding: 0.5rem;
    }
}

@media screen and (max-width: 300px) {
    .clickhere-link:hover {
        font-size: 1.3rem;
    }
}
