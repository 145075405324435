@import 'variables.scss';

@mixin sidebar-information {
    display: grid;
    grid-template-columns: 1fr 12fr;
    grid-template-rows: 1fr;
}

@mixin iconElement {
        display: flex;
        font-size: 1.5rem;
        margin-right: 0.8rem;
        color: $elementsBackground;
}

@mixin a_i_Element {
        display: flex;
        font-size: 1.5rem;
        margin-right: 0.8rem;
        color: $elementsBackground;
        align-items: center;
}

@mixin progressCircle {
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 5;
    stroke-dasharray: 185;
    stroke-dashoffset: 185;
    stroke-linecap: round;
    transform: translate(5px, 5px); // stroke-width / 2
}

@mixin  year_p {
    display: inline-block;
    position: absolute;
    /* top: 110px; */
    transform: translateX(-87px) rotate(270deg);
    /* margin: 0; */
    width: 150px;
    background: $elementsBackground;
    padding: 0.5rem;
    font-weight: 600;
    margin-top: 10vh;
    text-align: center;
    font-size: 0.9rem;
}

@mixin experiencesBlocksHeader {
    max-width: 150px;
    margin-top: 2rem;
    margin-bottom: 1rem;
    text-decoration: underline
}

@mixin animateSkills {
    content: '';
    //position: absolute;
    width: 100%;
    //transform: scale(0);
    height: 3px;
    bottom: 0;
    // left: 0;
    // background-color: orange;
    // transition-property: inherit;
    //transition: 5s ease-out;
}

@mixin animateSkillsHover {
    transform: scale(1);
    transform-origin: bottom left;
}