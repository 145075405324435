@import '~bootstrap/scss/bootstrap.scss';
@import 'variables.scss';

.active {
  background-color: #86a7a73b;
  border-bottom: 2px solid $elementsBackground;
}

.header_logo {
  display: none;
}

.navbar {
  &-brand {
    display: none;
    margin: 0;
    padding: 0;
  }
    &-nav a {
      justify-content: space-evenly;
      text-decoration: none;
      color: $elementsBackground;
      max-width: 100%;
      font-family: 'Lato', sans-serif;
      font-weight: 700;
      font-size: 1.1rem;
      transition: none!important;
      position: relative;
      padding: 1rem 1.5rem;

              &:hover {
                color: #ffa600!important;
                background-color: #86a7a73b;
                    &:after {
                      transform: scaleX(1);
                      transform-origin: bottom left; 
                    }
              }
              &:active {
                color: #ffa600!important;
                background-color: #86a7a73b;    
              }

        &:after {
          content: '';
          position: absolute;
          width: 100%;
          transform: scaleX(0);
          height: 2px;
          bottom: 0;
          left: 0;
          background-color: $elementsBackground;
          transition: transform 0.4s ease-out;
          text-align: center;
        }
    }
}

.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 30 30%27%3e%3cpath stroke=%27rgba%28255, 193, 7, 1%29%27 stroke-linecap=%27round%27 stroke-miterlimit=%2710%27 stroke-width=%272%27 d=%27M4 7h22M4 15h22M4 23h22%27/%3e%3c/svg%3e");
}
 

@media (max-width: 991px) {
    .header_logo {
      display: block;
    }

    .navbar {
      & .container-fluid {
          justify-content: space-between;
      }

      &-nav{
        align-items: center;
        justify-content: center;

          & a {
            font-size: 1rem;
            padding: 0.3rem 1rem;
          }
      }

      &-collapse {
        margin-top: 1rem;
      }

      &-expand-lg .navbar-nav .navbar-nav a {
        padding: 1rem 42.65%;
      }
    }
  }



  @media (max-width: 720px) {
    .navbar-expand-lg .navbar-nav .navbar-nav a {
        padding: 1rem 30%;
    }
  }