@import './variables.scss';


.contact {
    position: absolute;
    margin: 0;
    top: 8vh;
    display: flex;
    flex-direction: column;
    border: 1px solid $elementsBackground;
    border-radius: 0.5rem;
    background: $formBackground;
    overflow: hidden;

    & a {
        padding: 1.3rem;
        padding-bottom: 1.8rem;
        padding-top: 1.8rem;
        margin: 0;
        border-bottom: 1px solid $elementsBackground;

        &:last-child {
            border-bottom: none;
        }

        &:hover,
        &:active {
            background-color: $elementsBackground;
        }
    }

    &form {
        max-width: 70%;
        margin: 0 auto;
            &__required {
                font-weight: 600;
            }
    
        &Block {
            position: relative;
            padding-bottom: 1rem;
            padding-top: 0.5rem;
            background-image: linear-gradient(159deg, #2d2d3a 0%, #2b2b35 100%);
        }
        &__header {
            margin: 2rem 0;
            text-align: center;
        }
        &__button {
            margin: 1rem 0;
        }
    }
}


.form_textarea {
    margin-bottom: 0!important;
}


@keyframes zoomin {
    0% {
      transform: scale(0);
    }
    100% {
      transform: scale(1);
    }
  }

.success_message {
    border: 2px solid $elementsBackground;
    color: $elementsBackground;
    padding: 1rem 2rem;
    max-width: 50%;
    text-align: center;
    font-weight: 600;
    position: relative;
    cursor: pointer;
    animation-name: zoomin;
    animation-duration: 0.5s; 
    animation-iteration-count: 1;
    animation-timing-function: ease-out; 
    animation-delay: 0;

    &_shadow{
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-color: $elementsBackground;
        opacity: 0;
        z-index: 50;
        text-transform: uppercase;
        line-height: 3.5rem;
        letter-spacing: 0.05rem;
        color: white;

        &:hover,
        &:active {
            opacity: 1;        
        }
    }
}


@media screen and (max-width: 720px) {
    .contact {
        left: 0;
    }

    .success_message {
        max-width: 100%;
    }
}

@media screen and (max-width: 530px) {
    .contact {
        flex-direction: row;
        left: 0; 
        right: 0; 
        top: 100%;
        margin-left: auto; 
        margin-right: auto;
        margin-top: 0.8rem;
        max-width: 250px;
        border-radius: 1.5rem;
        
        & a {
            padding: 0.5rem 2rem!important;
            border-bottom: none;
            
            &:not(:first-child) {
                border-left: 1px solid $elementsBackground;
            }
        }

        &form {
            max-width: 94%;
        }
    }
}

@media screen and (max-width: 320px) {
    .contact {
        max-width: auto;

        & a{
            font-size: 1rem;
        }
    }
}
