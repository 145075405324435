@import '../components/variables.scss';
@import '../components/mixins.scss';



.bold {
    font-weight: $bold;
}

.block-header {
    background-color: $bodyBackground;
    text-align: center;
    padding: 2rem 0 1rem 0;
    margin: 0;
}



.workExperience-block,
.studyExperience-block {
    display: grid;
    gap: 1rem;
    grid-template-columns: 0.2fr 1fr 1fr 3fr;
    background-image: $containBackground;
    padding: 1rem 2rem;
}

.yearExperienceHeader {
    display: none;
    @include experiencesBlocksHeader
}

.workExperience {

        &__year p{
            @include year_p;
        }

        & h6 {
            @include experiencesBlocksHeader
        }

        &-companyBlock {
            display: flex;
            flex-direction: column;
        }

        &__job {
            text-align: justify;
            color: $text;

            & h6 {
                color: white;
            }
        }

        &__workPosition {
            color: $text;
        }

        
} 


hr {
    max-width: 80%;
    margin-left: 10%;
    margin-top: 2rem;
    margin-bottom: 2rem;
}


//STUDY BLOCK
.studyExperience {
    &-block {
        position: relative;
    }

    &__year p{
        @include year_p;
    }
    & h6 {
        @include experiencesBlocksHeader
    }

    &__content {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 3.1fr;
        margin-bottom: 5rem;
    }

    &__company,
    &__description,
    &__studyPosition {
        color: $text;
        text-align: justify;

        & h6 {
            color: white;
        }
    }

}


//CURSE BLOCK
.course {
    &Experience {
        &-block {
            display: grid;
            gap: 0.5rem;  
            grid-template-columns: 1fr 1fr 2fr; 
            background-image: $containBackground;
            padding: 1rem 2rem;   
        }
        & h6 {
            @include experiencesBlocksHeader
        }

        &__job {
            text-align: justify;
            grid-column: 3/4;
        }

        &__coursePosition,
        &__job {
            color: $text;

            & h6 {
                color: white;
            }
        }
    }

    &s_certificate {
        display: flex;

    }
}

.certificate {
    display: none;
}

.showup_certificate {
    margin-right: 5%;
    position: relative;
    height: fit-content;
    width: fit-content;
    cursor: pointer;

    & .certificate_eye {
        color: $elementsBackground;
        cursor: pointer;
    }

    &:hover>.certificate,
    &:active>.certificate {
        position: fixed;
        top: 8vh;
        display: block;
        z-index: 10;
        cursor: pointer;
        max-width: 600px;
        margin-left: 2rem;
    }
}

.skillmea,
.jstutorialnet {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin-bottom: 5rem;
}


// ---------MEDIA QUERIES ----------------- //

@media screen and (max-width: 991px) {
    .download_cv_cv {
        display: block;
    }
}

@media screen and (max-width:930px) {
    hr {
        display: block;
    }

    .sectorLine {
        display: none!important;
    }

    .workExperience-block,
    .studyExperience-block {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 0.5fr 3fr 3fr 0fr;
    }

    .courseExperience-block {
        display: grid;
        gap: 0.5rem;
        grid-template-columns: 2fr 2fr 0fr; 
    }

    .workExperience__job,
    .studyExperience__description {
        grid-column: 2/5;
    }

    .courseExperience__job {
        grid-column: 1/4;
    }
}

@media screen and (max-width: 720px) {
    .showup_certificate {
    
        &:hover>.certificate,
        &:active>.certificate {
            max-width: 400px;
        }
    }

    .courseExperience-block {
        padding: 0.5rem 1rem;
    }
}

@media screen and (max-width: 500px) {
    .showup_certificate {
    
        &:hover>.certificate,
        &:active>.certificate {
            max-width: 300px;
            margin-left: 0;
        }
    }
}


@media screen and (max-width:390px) {
    .workExperience-block, 
    .studyExperience-block, 
    .courseExperience-block {
        display: flex;
        flex-direction: column;
        gap: 0;
        padding: 0;

    }

    .workExperience,
    .studyExperience {
        margin: 0.5rem;

        & p,
        & h6 {
            margin: 0;
        }

        & a {
            display: none;
        }
    } 

    .workExperience__job,
    .studyExperience__description,
    .courseExperience__job {
        margin-bottom: 1rem;
    }

    .courseExperience {
        margin: 0.5rem;

        & p,
        & h6 {
            margin: 0;
        }

        &__company,
        &__coursePosition h6:nth-child(2) {
            display: none;
        }

        &-block {
            margin-left: 0;
        }
    } 

    .workExperience__company {
        display: none;
    }


    .yearExperienceHeader {
        display: block;
        margin-bottom: 0;
    }

    .workExperience__year p,
    .studyExperience__year p {
        display: block;
        position: inherit;
        background-color: transparent;
        transform: none;
        margin: 0;
        padding: 0;
        font-weight: 400;
        text-align: start;
        color: $text;
    }

    .workExperience__workPosition {
        font-weight: 400;
    }
}

@media screen and (max-width: 350px) {
    .showup_certificate {
    
        &:hover>.certificate,
        &:active>.certificate {
            max-width: 250px;
        }
    }
}


@media screen and (max-width: 270px) {
    .showup_certificate {
    
        &:hover>.certificate,
        &:active>.certificate {
            max-width: 200px;
        }
    }
}

@media screen and (max-width: 220px) {
    .showup_certificate {

        &:hover>.certificate,
        &:active>.certificate {
            max-width: 150px;
        }
    }
}
