@import 'variables.scss';

.topUp {
    position: fixed;
    font-size: 2rem;
    bottom: 8%;
    right: 3%;
    background-color: transparent;
    border: 1px solid #cececebb;
    color: white;
    padding: 0.3rem 1rem;
    border-radius: 30%;
    cursor: pointer;
    z-index: 2;

        &:hover,
        &:active {
            background-color: $elementsBackground;
            color: white;
            
        }
}

@media screen and (max-width: 1410px) {
    .topUp {
        position: fixed;
        font-size: 2rem;
        bottom: 8%;
        right: 3%;
        background: transparent;
        border: 1px solid white;
        color: $elementsBackground;
        padding: 0.3rem 1rem;
        border-radius: 30%;
        cursor: pointer;
        z-index: 2;
    }
}

@media screen and (max-width: 600px) {
    .topUp {
        padding: 0.1rem 0.7rem;
        margin-right: 0.2rem;
    }
}