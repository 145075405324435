@import '../components/mixins.scss';
@import '../components/variables.scss';

.hr_footer {
    margin-top: 4rem;
}

.iamhere--header {
    margin-bottom: 1.5rem;
}

.map {
    display: block;
}

.phone-block {
    position: relative;
    margin-left: 0.5rem;
}


.contact {
    &Block {
        display: flex;
        justify-content: space-between;
    }

    &info_header {
        display: none;
    }

    &-block__inPhone {
        display: flex;
        justify-content: space-around;
        margin-left: 2rem;
        margin-top: 1rem;
    }

    &-block__contacts {
        display: flex;
        margin: 1rem 0.9rem;
        font-size: 0.85rem;
        align-items: flex-end;

            & i {
                @include iconElement;
            }

            & a {
                text-decoration: none;
                color: $text;

                &:hover,
                &:active {
                        color: $elementsBackground;
                    }
                }
    }
}


.profilePicture {

    &onPhone_block {
    margin-bottom: 32%;
    margin-left: 1rem;

        & img {
            border: 2px solid $elementsBackground; 
        }
    }

    &_contact {
        border-radius: 50%;
    }
}


@media screen and (max-width: 1175px) {
.contact-block__inPhone {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 880px) {
.contact {
        &info_header {
            display: block;
            text-align: center;
            margin-bottom: 1rem;
        }
        &Block {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            align-items: stretch;
        }
        &form {
            margin-bottom: 1rem;
        }
        &-block__contacts {
            display: flex;
            margin: 0.7rem;
            align-items: center;
        }
    }
}


@media screen and (max-width: 720px) {
.map {
        width: 100%;
        margin: 0;
    }

.phone-block {
        margin: 0 auto;
        margin-top: 1rem;
    }

.contact {
        &-block__contact {
            max-width: 100%;
            margin-top: 2rem;
        }

        &-block__inPhone {
            margin-left: 0;
        }
    }
}